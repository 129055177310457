<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <v-spacer></v-spacer>
                            <div class="col-lg-2 col-12">
                                <v-text-field v-model="tripIdModel" label="TRIP ID" clearable dense disabled prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-5 col-12">
                                <v-switch v-model="newCustSwitchModel" label="New Customer" color="indigo" value="Y" hide-details @change="hideSearhCustomer()"></v-switch>
                            </div>
                            <div class="col-lg-5 col-12">
                                <v-autocomplete
                                    v-model="findCustomerModel"
                                    :items="findCustomerLists"
                                    item-value="CustomerId"
                                    item-text="NamaCustomer"
                                    :search-input.sync="search"
                                    cache-items
                                    flat
                                    hide-no-data
                                    hide-details
                                    clearable
                                    :disabled="showFindCustomerDisable"
                                    label="Customer" prepend-icon="mdi-account-multiple"
                                    @change="(event) => onChangeCustomer(event)"></v-autocomplete>
                            </div>
                            <div class="col-lg-4 col-12">
                                <v-text-field v-model="customerNameModel" label="CUSTOMER NAME" clearable dense :disabled="customerNameDisable" prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-8 col-12">
                                <v-text-field v-model="customerAddressModel" label="ADDRESS" clearable dense :disabled="customerAddressDisable" prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-5 col-12">
                                <v-text-field v-model="customerCityModel" label="CITY" clearable dense :disabled="customerCityDisable" prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-5 col-12">
                                <v-text-field v-model="customerProvinceModel" label="PROVINCE" clearable dense :disabled="customerProvinceDisable" prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-2 col-12">
                                <v-btn class="mt-3 mb-2" :color="btnCustGpsColor" elevation="2" small @click="showCustLocation()" :disabled="showCustGPSDisable" dark>Customer GPS Location<v-icon right dark>mdi-google-maps</v-icon></v-btn>
                            </div>
                            <div class="col-lg-6 col-12">
                                <div class="row">
                                    <div class="col-md-4 ml-3" style="text-align:center; ">
                                        <div class="checkbox">
                                            <v-checkbox dense v-model="checkbox1Model" :label="'PENAWARAN PRODUCT'" ></v-checkbox>
                                        </div>
                                        <div class="checkbox">
                                            <v-checkbox dense v-model="checkbox2Model" :label="'TAKING ORDER'" ></v-checkbox>
                                        </div>
                                        <div class="checkbox">
                                            <v-checkbox dense v-model="checkbox3Model" :label="'INFO PROGRAM/HADIAH'" ></v-checkbox>
                                        </div>
                                        <div class="checkbox">
                                            <v-checkbox dense v-model="checkbox4Model" :label="'PENAGIHAN'" ></v-checkbox>
                                        </div>
                                        <div class="checkbox">
                                            <v-checkbox dense v-model="checkbox5Model" :label="'CUSTOMER VISIT'" ></v-checkbox>
                                        </div>
                                    </div>
                                    <div class="col-md-4 ml-3" style="text-align:left">
                                        <input type="file" name="fileImage" ref="fileImage" id="fileImage" @change="fileImageChange" class="mt-3 fileImage" accept="image/*" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-12">
                                <v-btn class="mt-3 mb-2" color="primary" elevation="2" small @click="getLocation()">Get GPS Location<v-icon right dark>mdi-google-maps</v-icon></v-btn>
                                <div id="mapCanvas" style="height: 260px; width: 100%; border: 1px solid #213fad; border-radius: 10px;"></div>
                            </div>
                            <div class="col-lg-6 col-12">
                                <div class="row">
                                    <div class="col-lg-6 col-12">
                                        <v-text-field v-model="latitudeModel" label="LATITUDE" clearable dense disabled prepend-icon="mdi-ruler"></v-text-field>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <v-text-field v-model="longitudeModel" label="LONGITUDE" clearable dense disabled prepend-icon="mdi-ruler"></v-text-field>
                                    </div>
                                    <div class="col-lg-12 col-12">
                                        <v-text-field v-model="currentLocationModel" label="Current Location Address(Based on phone GPS)" clearable dense disabled prepend-icon="mdi-google-maps"></v-text-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 ">
                                 <!-- <v-btn color="primary" class="mb-2" elevation="2" small @click="getLocation()">Get GPS Location<v-icon right dark>mdi-google-maps</v-icon></v-btn>
                                 <div id="mapCanvas" style="height:260px; width:100%; border: 1px solid grey;"></div> -->
                                 <v-textarea v-model="remarkModel" label="REMARK" clearable outlined></v-textarea>
                                 <v-btn class="" block color="primary" elevation="2" medium @click="submitData()">SUBMIT<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                            <!-- <div class="col-lg-6 col-6">
                                <v-textarea v-model="remarkModel" label="REMARK" clearable outlined dense></v-textarea>
                            </div> -->
                            <!-- <div class="col-lg-12 col-12">
                                <v-btn class="" block color="primary" elevation="2" large rounded @click="submitData()">SUBMIT<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div> -->
                        </div>
                    </div>
                </div>

            </div>
            
        </div>

        <v-dialog v-model="dialogCustLocation" max-width="80%">
            <div class="card elevation-5">
                <div class="card-body mt-3">
                    <div class="row">
                        <div class="col-12">
                            <div id="mapCanvasCustomer" style="height: 450px; width: 100%; border: 3px solid #213fad; border-radius: 10px;"></div>
                        </div>
                        <div class="col-6 text-left">
                            <v-btn class="" color="green" elevation="2" small dense rounded @click="setCustGPSLocation()" dark>Set Address</v-btn>
                        </div>
                        <div class="col-6 text-right">
                            <v-btn class="" color="red" elevation="2" small dense rounded @click="dialogCustLocation = !dialogCustLocation" dark>close</v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </v-dialog>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Sales Activity',
                disabled: false,
                href: '#',
                },
                {
                text: 'Customer Visit',
                disabled: true,
                href: '#',
                },
            ],
            findCustomerModel: '',
            findCustomerLists: [],
            showFindCustomerDisable: false,
            customerNameModel: '',
            customerNameDisable: true,
            customerAddressModel: '',
            customerAddressDisable: true,
            customerCityModel: '',
            customerCityDisable: true,
            customerProvinceModel: '',
            customerProvinceDisable: true,
            longitudeModel: '',
            latitudeModel: '',
            currentLocationModel: '',
            mymap: '',
            showPosition: '',
            checkbox1Model: false,
            checkbox2Model: false,
            checkbox3Model: false,
            checkbox4Model: false,
            checkbox5Model: false,
            remarkModel: '',
            newCustSwitchModel: '',
            isLastVisit: false,
            isLastVisitMinutes: '',
            search: null,
            userGroup: null,
            appl_id: 'SALES ACTIVITY',
            imageModel: '',
            salesid: '',
            custLat: '',
            custLon: '',
            mymap2: '',
            dialogCustLocation: false,
            showCustGPSDisable: true,
            btnCustGpsColor: 'primary',
            tripIdModel: ''
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{

        async initialize(){

            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/CustomerVisit`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.salesid = res.data.salesid
                console.log(this.salesid)

                this.tripIdModel = res.data.trip_id

                this.$store.dispatch('setOverlay', false)

                this.$swal('Warning', 'Untuk mendukung data Analisa Lokasi Customer, ada fitur baru untuk set lokasi customer. Silahkan klik tombol Customer GPS Location untuk crosscheck lokasi existing customer di peta apakah sudah sesuai, jika belum bisa di set ulang sesuai posisi terbaru. Terima kasih', 'warning')

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async onChangeCustomer(id){
            
            if(id == null || id == '' ){

                this.customerNameModel = ''
                this.customerAddressModel = ''
                this.customerCityModel = ''
                this.customerProvinceModel = ''
                this.showCustGPSDisable = true
                this.btnCustGpsColor = 'orange'

            } else {

                this.$store.dispatch('setOverlay', true)

                await axios.get(`${process.env.VUE_APP_URL}/api/kmb/CustomerVisit/getCustomerDetails?id=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.customerNameModel = res.data.result.NamaCustomer
                    this.customerAddressModel = res.data.result.Alamat
                    this.customerCityModel = res.data.result.Kota
                    this.customerProvinceModel = res.data.result.Propinsi
                    this.custLat = res.data.result.latitude
                    this.custLon = res.data.result.longitude
                    this.showCustGPSDisable = false
                    
                    if(!this.custLat || !this.custLon){
                        this.$swal('Warning', 'Lokasi customer ini belum di set, klik tombol Customer GPS Location untuk set lokasi toko/gudang/rumah/proyek. Pastikan waktu set lokasi customer, salesman berada di lokasi toko customer. Terima Kasih', 'warning')
                        this.btnCustGpsColor = 'orange'
                    } else {
                        this.btnCustGpsColor = 'green'
                    }

                    this.$store.dispatch('setOverlay', false)
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            }
        },

        async showCustLocation(){

            if(!this.findCustomerModel){
                this.$swal('Warning', 'Please select existing customer first !', 'warning')
                return false
            }

            this.dialogCustLocation = true

            await this.$nextTick()

            if (this.mymap2 != '') {
                this.mymap2.off()
                this.mymap2.remove()
            }

            this.mymap2 = L.map('mapCanvasCustomer').setView([this.custLat, this.custLon], 18);
        
            L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_KMB}`, {
                maxZoom: 25,
                attribution: '<b>Customer location</b><br> ',
                id: 'mapbox/streets-v11',
                tileSize: 512,
                zoomOffset: -1
            }).addTo(this.mymap2)

            L.marker([this.custLat, this.custLon],
                {draggable: false,
                opacity: 0.75}
                )
                .addTo(this.mymap2)
                .bindPopup("<b>"+this.customerNameModel+"</b><br>")
                .openPopup()

        },

        async setCustGPSLocation(){

            var lat = ''
            var lon = ''

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {

                    lat = position.coords.latitude;
                    lon = position.coords.longitude;

                    this.custLat = lat
                    this.custLon = lon

                    axios.post(`${process.env.VUE_APP_URL}/api/kmb/CustomerVisit/setCustomerLocation`, { 
                        custId: this.findCustomerModel,
                        lat : lat,
                        lon : lon,
                    },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {

                        if (this.mymap2 != '') {
                            this.mymap2.off()
                            this.mymap2.remove()
                        }

                        this.mymap2 = L.map('mapCanvasCustomer').setView([res.data.lat, res.data.lon], 18);
                    
                        L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_KMB}`, {
                            maxZoom: 25,
                            attribution: '<b>Customer location</b><br> ',
                            id: 'mapbox/streets-v11',
                            tileSize: 512,
                            zoomOffset: -1
                        }).addTo(this.mymap2)

                        L.marker([res.data.lat, res.data.lon],
                            {draggable: false,
                            opacity: 0.75}
                            )
                            .addTo(this.mymap2)
                            .bindPopup("<b>"+this.customerNameModel+"</b><br>")
                            .openPopup()

                        this.$swal('Success', 'Lokasi Customer berhasil di set', 'success')
                        this.btnCustGpsColor = 'green'

                    })
                    .catch(err => {

                        this.$store.dispatch('setOverlay', false)
                        if(err.response.status == '401'){
                            this.$router.push('/');
                        }

                    })

                }, (error) => {
                    
                    console.error("Error occurred while getting location: ", error);
                    Swal.fire({
                        text: 'Please check GPS and click Allow Request GPS Access !',
                        icon: 'warning',
                    });

                });
            } else { 
                Swal.fire({
                    text: 'Geolocation is not supported by this browser.',
                    icon: 'warning',
                });
            }

        },

        async getCustomer(id){

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/CustomerVisit/getCustomer`, { 
                    id : id,
            },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                this.findCustomerLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async submitData(){

            // await this.checkLastVisit()

            if(this.salesid == '' || this.salesid == null){

                Swal.fire({
                    text: "We're sorry, this application only for Salesman !",
                    icon: 'warning',
                })

                return false

            }

            if(this.isLastVisit == false){

                // if(!this.currentLocationModel){

                //     Swal.fire({
                //         text: 'Please click get current location first !',
                //         icon: 'warning',
                //     })

                //     return false
                // }

                if(!this.latitudeModel || !this.longitudeModel){

                    Swal.fire({
                        text: 'Please click get current location first !',
                        icon: 'warning',
                    })

                    return false
                }

                this.$store.dispatch('setOverlay', true)

                await axios.post(`${process.env.VUE_APP_URL}/api/kmb/CustomerVisit/store`, { 

                        customerId: this.findCustomerModel ? this.findCustomerModel : '',
                        custName: this.customerNameModel ? this.customerNameModel : '',
                        custAddress: this.customerAddressModel ? this.customerAddressModel : '',
                        custCity: this.customerCityModel ? this.customerCityModel : '',
                        custProvince: this.customerProvinceModel ? this.customerProvinceModel : '',
                        checkbox1: this.checkbox1Model ? this.checkbox1Model : '',
                        checkbox2: this.checkbox2Model ? this.checkbox2Model : '',
                        checkbox3: this.checkbox3Model ? this.checkbox3Model : '',
                        checkbox4: this.checkbox4Model ? this.checkbox4Model : '',
                        checkbox5: this.checkbox5Model ? this.checkbox5Model : '',
                        latitude: this.latitudeModel ? this.latitudeModel : '',
                        longitude: this.longitudeModel ? this.longitudeModel : '',
                        currentLocation: this.currentLocationModel ? this.currentLocationModel : '',
                        remark: this.remarkModel ? this.remarkModel : '',
                        image: this.imageModel ? this.imageModel : '',
                        trip_id: this.tripIdModel ? this.tripIdModel : '',

                    },
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    }
                )
                .then(res => {

                    this.$store.dispatch('setOverlay', false)
                    Swal.fire({
                        text: 'Data submitted successfully',
                        icon: 'success',
                    }).then(() => {
                        // location.reload();
                    });

                    this.findCustomerModel  = ''
                    this.findCustomerLists = []
                    this.customerNameModel = ''
                    this.customerAddressModel = ''
                    this.customerCityModel = ''
                    this.customerProvinceModel = ''
                    this.latitudeModel = ''
                    this.longitudeModel = ''
                    this.currentLocationModel = ''
                    this.remarkModel = ''
                    this.checkbox1Model = false
                    this.checkbox2Model = false
                    this.checkbox3Model = false
                    this.checkbox4Model = false
                    this.checkbox5Model = false
                    $("#fileImage").val(null);

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            } else {
                Swal.fire({
                    text: 'Please wait ' + this.isLastVisitMinutes + ' minutes for doing another Customer Visit',
                    icon: 'warning',
                })

                return false
            }
        },

        async checkLastVisit(){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/CustomerVisit/checkLastVisit`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
                })
                .then(res => {
                    if(res.data.result >= 0){
                        this.isLastVisit = true
                        this.isLastVisitMinutes = res.data.result
                    } else {
                        this.isLastVisit = false
                        this.isLastVisitMinutes = res.data.result
                    }
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        emptyTab(){

            this.findCustomerModel = ''
            this.findCustomerLists = []

        },
        
        getLocation(){
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.getCurrentLocation)
            } else { 
                Swal.fire({
                    text: 'Please check GPS and click Allow Request GPS Access !',
                    icon: 'warning',
                })
            }
        },

        async getCurrentLocation(position){

            this.$store.dispatch('setOverlay', true)

            if (this.mymap != '') {
                this.mymap.off()
                this.mymap.remove()
            }

            this.latitudeModel = position.coords.latitude
            this.longitudeModel = position.coords.longitude

            // await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.latitudeModel}&lon=${this.longitudeModel}`, { 
            //     headers: { 
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json',
            //         // 'Access-Control-Allow-Origin': '*',
            //         Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            //     } 
            //     })
            //     .then(res => {
            //         // console.log(res.data.display_name);
            //         this.currentLocationModel = res.data.display_name
            // })
            // .catch(err => {

            //     console.log(err)

            //     this.$store.dispatch('setOverlay', false)
            //     if(err.response.status == '401'){
            //         this.$router.push('/');
            //     }

            // })

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/CustomerVisit/getAddress?lat=${this.latitudeModel}&lon=${this.longitudeModel}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.currentLocationModel = res.data.features[0].place_name

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err);                
                // if(err.response.status == '401'){
                //     this.$router.push('/');
                // }

            })

            this.mymap = L.map('mapCanvas').setView([this.latitudeModel, this.longitudeModel], 18);
        
            L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_KMB}`, {
                maxZoom: 18,
                attribution: '<b>Your current location</b><br> ' + this.currentLocationModel,
                id: 'mapbox/streets-v11',
                tileSize: 512,
                zoomOffset: -1
            }).addTo(this.mymap)

            L.marker([this.latitudeModel, this.longitudeModel],
                {draggable: false,     
                //title: 'Hover Text',
                opacity: 0.75}
                )
                .addTo(this.mymap)
                .bindPopup('<b>Your current location</b><br> ' + this.currentLocationModel)
                .openPopup()

            this.$store.dispatch('setOverlay', false)

        },

        hideSearhCustomer(){

            if(this.newCustSwitchModel == 'Y'){

                this.findCustomerModel  = ''
                this.findCustomerLists = []
                this.showFindCustomerDisable = true
                this.customerNameModel = ''
                this.customerNameDisable = false
                this.customerAddressModel = ''
                this.customerAddressDisable = false
                this.customerCityModel = ''
                this.customerCityDisable = false
                this.customerProvinceModel = ''
                this.customerProvinceDisable = false
            } else {
                this.showFindCustomerDisable = false
                this.customerNameModel = ''
                this.customerNameDisable = true
                this.customerAddressModel = ''
                this.customerAddressDisable = true
                this.customerCityModel = ''
                this.customerCityDisable = true
                this.customerProvinceModel = ''
                this.customerProvinceDisable = true
            }

        },

        fileImageChange(e){

            let file = e.target.files[0]
            this.imageModel = ''

            if(file){

                if (!file.type.includes('image/')) {
                    swal("Error", "Please select an image file!", "error");
                    return
                }
                // if (file.size > 10000000) {
                //     swal("Failed", "File image maximum 10 MB!", "error");
                //     return
                // }
                if (typeof FileReader === 'function') {
                    const reader = new FileReader()
                    reader.onload = (event) => {
                        this.imageModel = event.target.result
                    }
                    reader.readAsDataURL(file)
                } else {
                    swal("Error", "Sorry, FileReader API not supported", "error");
                }

            }

        }

    },
    watch: {
        search (val) {
            val && val !== this.findCustomerModel && this.getCustomer(val)
        },
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .vertical-align {
        align-items: center;
    }

</style>